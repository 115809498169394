.box__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  background: #fff;
  z-index: 100;
  &.sub {
    border-bottom: 1px solid #e5e5e5;
  }
  .box__inner {
    position: relative;
  }
  .logo {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    text-indent: -999px;
    background: url("../images/logo3.png") no-repeat 0 50%;
    background-size: 100% auto;
    overflow: hidden;
    & a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .box__component-weather {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    max-width: 100px;
    height: 50px;
    z-index: 110;
    flex-direction: row-reverse;
    .image {
      width: 35px;
    }
  }

  .link__myg {
    position: absolute;
    top: 10px;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    .icon {
      display: block;
      width: 36px;
      margin-left: -4px;
    }
  }
}

.theme-black {
  background: var(--themeBlack);
  .box__header {
    background: var(--themeBlack);
    &.sub {
      border-bottom: 1px solid #444;
    }
    .logo {
      background-image: url("../images/logo-white.png");
    }
  }
}

.page__setting {
  .list__setting {
    .list-item {
      padding: 20px 10px;
      font-weight: bold;
      font-size: 18px;
      & + .list-item {
        border-top: 1px solid #eeeeee;
      }
      a,
      button {
        font-weight: 700;
        color: #424242;
        border: none;
        background: none;
      }
    }
  }
  .box__user {
    display: flex;
    padding: 10px 20px;
    background: #f5f5f5;
    border-radius: 12px;
    align-items: center;
    .link__login {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 20px;
        width: 20px;
        height: 20px;
      }
    }
    .text__grade {
      width: 60px;
      height: 60px;
      padding: 10px;
      margin-right: 20px;
      background: #fff;
      border-color: #424242;
      .image {
        max-width: 100%;
      }
    }
  }
  .box__detail-option {
    margin-top: 10px;
    background: #f5f5f5;
    border-radius: 12px;
    padding: 10px 20px;
    .box__option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:first-child) {
        margin-top: 10px;
      }
      .text__title {
        font-size: 15px;
      }
      .box__select {
        min-width: 100px;
        padding: 5px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        background: #fff;
      }
    }
  }
  .theme-black & {
    .list__setting {
      .list-item {
        & + .list-item {
          border-color: var(--themeBlackSub);
        }
        a,
        button {
          color: var(--themeBlackColor);
        }
      }
    }
    .box__user {
      background: var(--themeBlackSub);
    }
    .box__detail-option {
      background: var(--themeBlackSub);
      .box__option {
        .box__select {
          background: var(--themeBlackSub);
        }
      }
    }
  }
}

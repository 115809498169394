.list__grade {
  display: flex;
  max-width: var(--maxWidth);
  margin: 0 auto;
  flex-wrap: wrap;
  .list-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    @media all and (min-width: 768px) {
      max-width: calc(50% - 8px);
      margin-right: 15px;
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }
  .box__icon {
    width: 90px;
    height: 90px;
    padding: 10px;
    margin-right: 20px;
    border-radius: 50%;
    border: 2px solid #e5e5e5;
    text-align: center;
    .image {
      max-height: 100%;
    }
  }
  .box__info {
    flex: 1;
    .text__title {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 16px;
    }
    .text__desc {
      padding: 0;
      margin: 0;
    }
  }
  .theme-black & {
    .box__icon {
      border-color: var(--themeBlackSub);
      background-color: rgba(255, 255, 255, 0.8);
    }
    .text__desc {
      opacity: 0.7;
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 0;
  border-top: 1px solid #e5e5e5;
  background-color: rgb(253, 250, 250, 1);
  text-align: center;
  .box__inner {
    display: flex;
    align-items: center;
  }
  .link__footer {
    display: inline-block;
    width: 100%;
    .icon {
      width: 26px;
      margin: 0 auto;
    }
    .text {
      display: block;
      margin-top: 2px;
    }
    &:active,
    &:focus {
      .text {
        font-weight: bold;
      }
    }
  }

  .theme-black & {
    background-color: var(--themeBlack);
    border-color: var(--themeBlackSub);
  }
}

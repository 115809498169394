.page__product {
  max-width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  .box__category-store {
    margin-bottom: 20px;

    .button__category {
      display: inline-block;
      width: calc(50% - 8px);
      padding: 20px;
      margin-right: 16px;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
      color: #333;
      border-radius: 8px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      border: none;
      background: #fff;
      .box__icon {
        margin: 0 auto 10px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }
  .box__category-product {
    text-align: center;

    .button__category {
      display: inline-block;
      margin: 0 5px 16px;
      font-size: 14px;
      font-weight: 700;
      color: #333;
      border: none;
      background: none;
      .box__icon {
        width: 60px;
        height: 60px;
        padding: 15px;
        margin: 0 auto 10px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .theme-black & {
    background: var(--themeBlack);
    .box__category-store {
      .button__category {
        background: var(--themeBlackSub);
      }
    }
    .box__category-product {
      .button__category {
        .box__icon {
          background: var(--themeBlackSub);
        }
      }
    }
  }
  .box__filter-search {
    position: relative;
    max-width: var(--maxWidth);
    margin: 0 auto 20px;
    text-align: right;
    .box__inner {
      display: inline-block;
      width: 180px;
      height: 40px;
      padding: 0 40px 0 10px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.03);
      text-align: left;
      overflow: hidden;
      &:has(.box__search--active) {
        box-shadow: none;
      }
    }
    .text__local {
      font-size: 15px;
      line-height: 40px;
      &:before {
        content: "";
        display: inline-block;
        margin: 10px 2px 0 0;
        width: 20px;
        height: 20px;
        background: url("../images/icon/icon_location.png") no-repeat center
          center / 100%;
        vertical-align: top;
      }
    }
    .box__search {
      position: absolute;
      top: 0;
      right: 0;
      width: 180px;
      border-radius: 20px;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        display: block;
        width: 20px;
        height: 20px;
        background: url("../images/icon/icon_location.png") no-repeat center
          center / 100%;
      }
      .form__input {
        opacity: 0;
        width: calc(100% - 40px);
        height: 40px;
        padding: 5px 0 5px 32px;
        font-size: 15px;
        border: none;
        border-radius: 20px 0 0 20px;
      }
      .button__search {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 40px;
        padding: 10px;
        border: none;
        background: none;
        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 10px);
          right: 10px;
          display: block;
          width: 20px;
          height: 20px;
          background: url("../images/icon/icon_search.png") no-repeat center /
            100%;
        }
      }
      &.box__search--active {
        transform: scale(1);
        box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.03);
        .form__input {
          opacity: 1;
        }
        .button__search {
          width: 40px;
          animation: shakingMotion 0.3s ease-in-out;
        }
      }
    }
  }
  .list__store {
    max-width: var(--maxWidth);
    padding: 0 10px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    .list-item + .list-item {
      border-top: 1px solid #e5e5e5;
    }
    .link__store {
      display: flex;
      padding: 10px 0;
      align-items: center;
      .box__thumbnail {
        width: 80px;
        height: 80px;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        overflow: hidden;
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .box__info {
        margin-left: 12px;
        flex: 1;
        .text__name {
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: bold;
          color: var(--fontColor);
        }
        .text__address,
        .text__number {
          color: #666;
        }
      }
    }
  }

  .theme-black & {
    .box__filter-search {
      .box__inner {
        background: rgba(255, 255, 255, 0.1);
      }
      .text__local:before {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg)
          brightness(103%) contrast(103%);
      }
      .box__search {
        background: none;
        .button__search:before {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg)
            brightness(103%) contrast(103%);
        }
        &.box__search--active {
          .button__search:before {
            filter: none;
          }
        }
      }
    }
    .list__store {
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
@keyframes shakingMotion {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

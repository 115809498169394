.box__issue {
  position: relative;
  max-width: 100%;
  padding: 60px 0 40px;
  background: #f5f5f5;
  .box__tab-container {
    position: relative;
    margin-top: -40px;
    z-index: 0;
  }
  .box__tab {
    position: sticky;
    top: 60px;
    left: 0;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 100;
    .nav-link {
      &.active {
        font-weight: bold;
        background-color: #f5f5f5;
        border-bottom-color: #f5f5f5;
      }
    }
  }
  .tab-content {
    position: relative;
    margin: 60px 16px 65px;
  }
  .box__card-wrap {
    display: none;
    width: 100%;
    .box__card-inner {
      display: flex;
      padding: 0 10px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 1px 1px 3px rgba(0, 0, 0, 0.03);
      flex-wrap: wrap;
    }
    &.active {
      display: block;
    }
    .card {
      margin: 0;
      padding: 10px 0;
      border: none;
      border-radius: 0;
      & + .card {
        border-top: 1px solid #e5e5e5;
      }
    }
  }
  .theme-black & {
    .box__tab {
      background-color: var(--themeBlack);
      border-color: var(--themeBlackSub);
      .nav-link {
        &.active {
          font-weight: bold;
          background-color: rgba(255, 255, 255, 0.8);
          border-color: var(--themeBlackSub);
        }
      }
    }
    .card {
      background: var(--themeBlack);
      border: var(--themeBlackSub);
    }
  }
}
@media all and (min-width: 768px) {
  .box__issue {
    .box__card-wrap {
      .box__card-inner {
        .card:nth-child(-n + 2) {
          border-top: none;
        }
      }
    }
  }
}
.box__card-issue {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  .link {
    position: relative;
    display: flex;
    width: 100%;
    border: none;
    background: transparent;
    flex-direction: row;
    align-items: center;
    text-align: left;
    z-index: 0;
  }
  @media all and (min-width: 450px) {
    width: calc(50% - 8px);
    margin-right: 15px;
    &:nth-child(even) {
      margin-right: 0;
    }
  }
  .box__thumb {
    width: 70px;
    height: 70px;
  }
  .box__text {
    width: 100%;
    .text__title {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .button__favorite {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 15px;
      height: 15px;
      background: url("../images/icon/icon__favorite.png") no-repeat center /
        100%;
      transform: translate(-50%, -50%);
    }
    &--active {
      &:before {
        background-image: url("../images/icon/icon__favorite-active.png");
        animation: favoriteMotion 0.4s ease;
      }
    }
    @keyframes favoriteMotion {
      0% {
        transform: translate(-50%, -50%) scale(1);
      }
      50% {
        transform: translate(-50%, -50%) scale(1.3);
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}
.box__keyword-filter {
  position: sticky;
  top: 60px;
  left: 100%;
  width: 100px;
  z-index: 110;
  .box__dimmed {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .box__keyword-wrap {
    position: absolute;
    top: 0;
    right: 16px;
    width: 100%;
    height: 30px;
    background-color: rgba(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    overflow: hidden;
    .list__keyword {
      position: relative;
      padding: 0 10px;
      animation: rollingMotion 13s 0.2s ease-in-out infinite;
      .list-item {
        display: flex;
        font-size: var(--fontSize);
        line-height: 30px;
      }
      .text__rank {
        position: relative;
        display: block;
        width: 12px;
        margin-right: 12px;
        opacity: 0.4;
        text-align: center;
        &:after {
          content: "";
          position: absolute;
          top: calc(50% - 5px);
          right: -6px;
          display: block;
          width: 1px;
          height: 10px;
          background: var(--fontColor);
        }
      }
      .text {
        flex: 1;
        display: block;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  &.box__keyword-filter--active {
    .box__keyword-wrap {
      height: auto;
      .list__keyword {
        padding: 5px 10px;
        background-color: rgba(255, 254, 254, 0.7);
        animation: none;
        z-index: 10;
      }
    }
    .box__dimmed {
      display: block;
    }
  }
  .button__keyword {
    display: flex;
    width: 100%;
    padding: 0 10px;
    font-size: var(--fontSize);
    line-height: 26px;
    background: none;
    border: none;
    text-align: left;
    .text__rank {
      position: relative;
      display: block;
      width: 12px;
      margin-right: 12px;
      opacity: 0.4;
      text-align: center;
      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 5px);
        right: -6px;
        display: block;
        width: 1px;
        height: 10px;
        background: var(--fontColor);
      }
    }
    .text {
      flex: 1;
      display: block;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .theme-black & {
    .box__keyword-wrap {
      border-color: none;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .list__keyword {
      .text__rank {
        &:after {
          background: var(--themeBlackColor);
        }
      }
    }

    &.box__keyword-filter--active {
      background-color: rgba(89, 89, 89, 0.98);
      .list__keyword {
        background-color: rgba(89, 89, 89, 0.98);
      }
    }
  }
}
@keyframes rollingMotion {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-30px);
  }
  20% {
    transform: translateY(-60px);
  }
  30% {
    transform: translateY(-90px);
  }
  40% {
    transform: translateY(-120px);
  }
  50% {
    transform: translateY(-150px);
  }
  60% {
    transform: translateY(-180px);
  }
  70% {
    transform: translateY(-210px);
  }
  80% {
    transform: translateY(-240px);
  }
  90% {
    transform: translateY(-270px);
  }
  100% {
    transform: translateY(-300px);
  }
}
.pagination {
  max-width: var(--maxWidth);
  margin: 20px auto 0;
  text-align: center;
  justify-content: center;
  .button__page {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 3px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.03);
    &.button__page--active {
      margin: 0 8px;
      font-weight: bold;
      background: #fff;
      transform: scale(1.2);
    }
  }

  .button__prev,
  .button__next {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.03);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 12px;
      height: 12px;
      background: url("../images/icon/icon__arrow.png") no-repeat center / 100%;
      transform: translate(-50%, -50%) rotate(90deg);
      filter: brightness(0) saturate(100%) invert(20%) sepia(1%) saturate(9%)
        hue-rotate(316deg) brightness(98%) contrast(79%);
    }
  }
  .button__next:before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}
.pagination .page-item .page-link {
  color: black !important;
  margin-top: 10px;
  margin-bottom: 20px;
}
.pagination .page-item.active .page-link {
  background-color: black !important;
  border-color: black !important;
  color: white !important;
}

.box__mypage-board {
  .box__title {
    margin-bottom: 20px;
  }
  .box__tab {
    margin-bottom: 15px;
  }
  .box__tab-content {
    position: relative;
    display: none;
    margin-bottom: 60px;
    flex-wrap: wrap;
    &.active {
      display: flex;
    }
  }
  .box__card {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    img {
      max-width: 100%;
    }
    .box__label-type {
      display: inline-block;
      padding: 2px 4px;
      margin-right: 5px;
      font-size: 9px;
      background: #fff;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      vertical-align: middle;
    }
    @media all and (min-width: 450px) {
      width: calc(50% - 8px);
      margin-right: 15px;
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  .theme-black & {
    .box__card {
      background: rgba(255, 255, 255, 0.1);
      .box__label-type {
        color: var(--themeBlack);
        background: #fff;
        border: 1px solid #e0e0e0;
      }
      &.text-dark {
        .card-header,
        .card-body * {
          color: var(--themeBlack);
        }
      }
    }
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.social-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.social-icon {
  width: 60px;
  height: 60px;
}

.icon-text {
  margin-left: 30px;
  margin-top: 10px;
  font-weight: bold;
}
